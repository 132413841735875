import { OnInit } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { EventEmitter, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { SoctripPromotionControllerService } from '@soctrip/angular-promotion-service';

@Component({
  selector: 'app-add-voucher-modal',
  templateUrl: './add-voucher-modal.component.html',
  styleUrls: ['./add-voucher-modal.component.scss'],
})
export class AddVoucherModalComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;

  @Input() max: number = 0;
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedVouchers: any[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedVouchersChange = new EventEmitter<any[]>();

  _vouchers: any[] = [];
  _totalVouchers = 0;

  isFetching = false;
  isFetched = false;

  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChanged = new Subject<string>();

  constructor(
    private promotionService: SoctripPromotionControllerService,
    public fileService: FileService,
  ) {}

  ngOnInit() {
    this.keywordChanged.pipe(debounceTime(800)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this._fetchVouchers(true);
      },
    });

    this._fetchVouchers(true);
  }

  _fetchVouchers(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    if (this.isFetched) {
      this.isFetching = true;
    }

    this.promotionService
      .getSoctripVoucherManagement(
        'SHOP',
        'ALL',
        '',
        this.pagination.page,
        this.pagination.rows,
      )
      .subscribe({
        next: (res: any) => {
          this._vouchers = res.data.data;
          this._totalVouchers = res.data.totalElement;
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
        error: () => {
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
      });
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedVouchers = [...this.selectedVouchers].slice(0, this.max);
    this.selectedVouchersChange.emit(this.selectedVouchers);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }

  isRowDisabled(voucher: any) {
    if (this.selectedVouchers.length >= this.max) {
      return !this.selectedVouchers.some((item) => item.id === voucher.id);
    }
    return false;
  }
}
