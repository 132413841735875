import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  CampaignBlockDTO,
  CampaignLayerRequestDTO,
  MarketingCampaignControllerService,
  MarketingCampaignRequestDTO,
} from '@soctrip/angular-marketing-hub-service';
import { MessageService } from 'primeng/api';
import { delay } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { PREVIEW_DEVICE } from 'src/app/core/enum/marketing-builder';
import { Layer } from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-header-builder',
  templateUrl: './header-builder.component.html',
  styleUrls: ['./header-builder.component.scss'],
})
export class HeaderBuilderComponent {
  @Input() campaignId: string = '';
  @Input() marketingName: string = '';
  @Input() updatedAt: Date;
  @Input() mode: string = 'BUILDER';
  @Input() layers: Layer[] = [];
  @Input() previewDevice: string = PREVIEW_DEVICE.DESKTOP;

  @Output() marketingNameChange = new EventEmitter<string>();
  @Output() updatedAtChange = new EventEmitter<Date>();
  @Output() modeChange = new EventEmitter<string>();
  @Output() previewDeviceChange = new EventEmitter<string>();

  readonly SoctripIcons = SoctripIcons;
  readonly DEVICES = [
    {
      title: 'Desktop',
      value: 'DESKTOP',
      icon: SoctripIcons.MONITOR_03,
    },
    {
      title: 'Mobile',
      value: 'MOBILE',
      icon: SoctripIcons.PHONE_02,
    },
  ];

  languages = [
    {
      name: 'Vietnam',
      code: 'vn',
    },
  ];
  languageSelected = 'vn';
  isUpdating: boolean = false;

  constructor(
    private router: Router,
    private marketingCampaignService: MarketingCampaignControllerService,
    private messageService: MessageService,
  ) {}

  get isBuilder() {
    return this.mode === 'BUILDER';
  }

  get isPreview() {
    return this.mode === 'PREVIEW';
  }

  onMarketingNameChange(event: string) {
    this.marketingName = event;
    this.marketingNameChange.emit(this.marketingName);
  }

  onChangeDevice(event: string) {
    this.previewDevice = event;
    this.previewDeviceChange.emit(this.previewDevice);
  }

  onPreview() {
    this.mode = 'PREVIEW';
    this.modeChange.emit(this.mode);
  }

  onBack() {
    if (this.mode === 'BUILDER') {
      this.router.navigate(['landing-page']);
    } else {
      this.mode = 'BUILDER';
      this.modeChange.emit(this.mode);
    }
  }

  getMarketingCampaignBody(isActive: boolean) {
    const body: MarketingCampaignRequestDTO = {
      title: this.marketingName,
      country_code: this.languageSelected,
      is_active: isActive,
      layers: [],
    };

    this.layers.forEach((layer, layerIndex) => {
      const layerConvert: CampaignLayerRequestDTO = {
        id: layer.id,
        title: layer.name,
        description: layer.description,
        order: layerIndex,
        code: 'ONE_ONE',
        is_new: layer.isNew,
        blocks: [],
      };

      layer.blocks.forEach((block, blockIndex) => {
        const filters: string[] = [];
        const productSorts: string[] = [];

        const {
          promotions,
          keywords,
          categories,
          product_ratings,
          cities,
          shops,
          specific_products,
          sorts,
          minPrice,
          maxPrice,
        } = block;

        if (promotions?.length) {
          filters.push(
            `flash_deal_docs.id==${promotions
              .map((promotion) => promotion.id)
              .join('|')}`,
          );
        }

        if (keywords?.length) {
          filters.push(`name@=${keywords.join('|')}`);
        }

        if (categories?.length) {
          filters.push(
            `str_catalog@=${categories
              .map((category) => category.id)
              .join('|')}`,
          );
        }

        // if (product_ratings?.length) {
        //   filters.push(
        //     `${product_ratings.map((rating) => `point==${rating}`).join(',')}`,
        //   );
        // }
        if (product_ratings) {
          filters.push(`point>=${product_ratings}`);
        }

        if (cities?.length) {
          filters.push(
            `str_address@=${cities.map((city) => city.name).join('|')}`,
          );
        }

        if (shops?.length) {
          filters.push(`shop_id==${shops.map((shop) => shop.id).join('|')}`);
        }

        if (specific_products?.length) {
          filters.push(
            `product_id==${specific_products
              .map((product) => product.id)
              .join('|')}`,
          );
        }

        if (sorts?.length) {
          sorts.forEach((item) => {
            productSorts.push(`${item.value > 0 ? '' : '-'}${item.key}`);
          });
        }

        if (minPrice) {
          filters.push(`usd_price>=${minPrice}`);
        }

        if (maxPrice) {
          filters.push(`usd_price<=${maxPrice}`);
        }

        const blockConvert: CampaignBlockDTO = {
          code: block.type,
          text: block.text,
          description: block.description,
          row: block.flex,
          order: blockIndex,
          line: block.line,
          items_per_line: block.itemPerLine,
          media: block.medias.map((media) => ({
            // Desktop
            media_desktop_id: media.media1?.id,
            desktop_name: media.media1?.name,
            desktop_size: media.media1?.size,
            desktop_link: media.link1,

            // Mobile
            media_mobile_id: media.media2?.id,
            mobile_name: media.media2?.name,
            mobile_size: media.media2?.size,
            mobile_link: media.link2,

            is_open_in_new_tab: media.isOpenNewTab,
          })),
          module: (block.module as CampaignBlockDTO.ModuleEnum) || 'SHOP',
          filter: filters.join(','),
          sort: productSorts.join(','),
        };

        if (layerConvert.blocks) {
          layerConvert.blocks.push(blockConvert);
        }
      });

      body.layers?.push(layerConvert);
    });

    return body;
  }

  onSave(isActive: boolean) {
    const body: MarketingCampaignRequestDTO =
      this.getMarketingCampaignBody(isActive);
    this.isUpdating = true;
    if (this.campaignId && this.campaignId !== 'new') {
      this.marketingCampaignService
        .marketingCampaignsIdPut(body, this.campaignId)
        .pipe(delay(100))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              detail: 'Update marketing campaign successfully',
            });

            this.isUpdating = false;

            this.updatedAt = res.data?.updated_at || new Date();
            this.updatedAtChange.emit(this.updatedAt);
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              detail: 'Update marketing campaign failed',
            });

            this.isUpdating = false;
          },
        });
    } else {
      this.marketingCampaignService
        .marketingCampaignsPost(body)
        .pipe(delay(100))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              detail: 'Create marketing campaign successfully',
            });

            this.isUpdating = false;

            this.updatedAt = res.data?.updated_at || new Date();
            this.updatedAtChange.emit(this.updatedAt);
          },
          error: (error) => {
            console.log(error);

            this.messageService.add({
              severity: 'error',
              detail: 'Create marketing campaign failed',
            });

            this.isUpdating = false;
          },
        });
    }
  }
}
