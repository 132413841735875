<input
  class="hidden"
  #uploadVideoInput
  type="file"
  (change)="onSelectVideo($event); uploadVideoInput.value = ''"
/>

<div class="flex flex-col gap-4">
  <div class="rounded-lg h-9 flex items-center p-[2px] bg-gray-100">
    <div
      *ngFor="let TYPE of TYPES"
      (click)="type = TYPE.value"
      [ngClass]="
        type === TYPE.value
          ? 'bg-white font-semibold'
          : 'font-medium text-gray-500'
      "
      class="flex-1 h-8 flex items-center justify-center rounded-md text-sm cursor-pointer"
    >
      {{ TYPE.name }}
    </div>
  </div>

  <div class="pb-5" [ngSwitch]="type">
    <ng-container *ngSwitchCase="TAB_MEDIA.MEDIA1">
      <div
        class="flex items-center gap-3"
        *ngIf="video; else uploadImageTemplate"
      >
        <div class="border rounded-lg">
          <div class="h-24 w-24 flex items-center justify-center">
            <img
              [src]="fileService.getThumbnailVideo(video.id)"
              alt="Thumbnail"
            />
          </div>
        </div>

        <div class="h-24 flex flex-col justify-between">
          <div class="flex flex-col gap-[2px] text-sm">
            <span class="font-medium">{{ video.name || "--" }}</span>
            <span class="text-gray-500"></span>
            <span class="text-gray-500">{{ video.size || "--" }}</span>
          </div>
          <div class="flex items-center gap-2">
            <app-button
              variant="outline-secondary"
              label="Replace"
              styleClass="!px-3"
              (onClick)="uploadVideoInput.click()"
            ></app-button>
            <app-button
              variant="outline-secondary"
              label="Delete"
              (onClick)="deleteVideo.emit()"
            ></app-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="TAB_MEDIA.MEDIA2">
      <input
        class="h-9 border-gray-300 rounded-lg w-full"
        type="text"
        pInputText
        [(ngModel)]="linkYoutube"
        placeholder="https://www.youtube.com/watch?v="
        (ngModelChange)="linkYoutubeChange.emit(linkYoutube)"
      />
    </ng-container>
  </div>
</div>

<ng-template #uploadImageTemplate>
  <div class="relative flex flex-col gap-2 mb-3">
    <p-progressSpinner
      *ngIf="isLoading"
      class="absolute -inset-2 bg-gray-300/50 flex items-center justify-center rounded-lg"
      styleClass="!w-16 !h-16"
    ></p-progressSpinner>

    <div
      (drop)="onDrop($event)"
      (dragleave)="onDragLeave($event)"
      (dragover)="onDragOver($event)"
      class="flex flex-col items-center gap-3 py-4 px-6 rounded-lg border border-dashed bg-white"
    >
      <div
        class="w-8 h-8 flex items-center justify-center bg-blue-100 rounded-full"
      >
        <i class="{{ SoctripIcons.UPLOAD_CLOUD_01 }} text-lg text-blue-600"></i>
      </div>

      <div class="flex flex-col gap-1 items-center">
        <div class="text-sm flex items-center gap-1">
          <span
            class="text-primary-600 cursor-pointer hover:underline"
            (click)="uploadVideoInput.click()"
            >Click to upload</span
          >
          <span class="text-gray-500">or drag and drop</span>
        </div>

        <span class="text-gray-500 text-sm">
          Accepts MP4 or AVI (Max 10MB)</span
        >
      </div>
    </div>
  </div>
</ng-template>
