<div>
  <!-- DISPLAY -->
  <div class="px-5 pb-5 border-b">
    <h2 class="py-4 font-semibold text-sm">Display</h2>

    <div class="flex flex-col gap-3">
      <!-- Line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3">Line</span>
        <p-inputNumber
          class="h-9"
          [min]="1"
          [max]="5"
          [(ngModel)]="blockData.line"
          (onInput)="onLineChange($event)"
        />
      </div>

      <!-- Items per line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3"
          >Items per line
        </span>
        <p-inputNumber
          class="h-9"
          [min]="4"
          [max]="6"
          [(ngModel)]="blockData.itemPerLine"
        />
      </div>
    </div>
  </div>

  <div class="px-5 pb-5 border-b">
    <h2 class="py-4 font-semibold text-sm">
      Vouchers ({{ blockData.vouchers.length }}/{{ maxVoucher }})
    </h2>

    <div
      class="button"
      [ngClass]="{
        'bg-gray-50 text-gray-300 hover:!bg-gray-50 !cursor-default':
          maxVoucher === blockData.vouchers.length
      }"
      (click)="onToggleAddVoucher()"
    >
      Select voucher
    </div>

    <div class="pt-4 flex flex-col gap-3">
      <div
        class="flex items-center gap-2"
        *ngFor="let voucher of blockData.vouchers; let i = index"
      >
        <div class="flex flex-col">
          <i
            class="{{ SoctripIcons.CHEVRON_UP }}"
            [ngClass]="i === 0 ? 'opacity-50' : 'cursor-pointer'"
            (click)="i !== 0 ? onMoveUpSort(i) : null"
          ></i>
          <i
            class="{{ SoctripIcons.CHEVRON_DOWN }}"
            [ngClass]="
              i === blockData.sorts.length - 1 ? 'opacity-50' : 'cursor-pointer'
            "
            (click)="
              i !== blockData.sorts.length - 1 ? onMoveDownSort(i) : null
            "
          ></i>
        </div>
        <div class="flex-1 flex items-center gap-2">
          <div class="w-10 h-10">
            <app-custom-image
              styleClass="w-full h-full rounded overflow-hidden"
              [imgId]="voucher.avatar_id"
              [alt]="voucher.name"
            ></app-custom-image>
          </div>

          <div class="flex flex-col justify-between">
            <span class="font-medium text-sm">Discount 10%</span>
            <span class="text-xs text-gray-500"
              >Valid through 23:59, 09/09/2024</span
            >
          </div>
        </div>
        <i
          class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
          (click)="onDeleteVoucher(voucher.id)"
        ></i>
      </div>
    </div>

    <app-add-voucher-modal
      [max]="blockData.line * blockData.itemPerLine"
      [(isModal)]="isModalVoucher"
      [(selectedVouchers)]="blockData.vouchers"
    ></app-add-voucher-modal>
  </div>
</div>
