import { Component, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import {
  BLOCKS_BUILDER,
  LAYOUTS_BUILDER,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-left-bar-builder',
  templateUrl: './left-bar-builder.component.html',
  styleUrls: ['./left-bar-builder.component.scss'],
})
export class LeftBarBuilderComponent {
  @Input() layers: Layer[] = [];
  @Input() elementSelected: ElementSelected;

  readonly BLOCKS = BLOCKS_BUILDER;
  readonly LAYOUTS = LAYOUTS_BUILDER;
  readonly SoctripIcons = SoctripIcons;

  readonly options = [
    { name: 'Layout', value: 'LAYOUT' },
    { name: 'Block', value: 'BLOCK' },
    { name: 'Style', value: 'STYLE' },
  ];

  tab = { name: 'Layout', value: 'LAYOUT' };

  treeLayers: TreeNode[] = [];

  selection: TreeNode<any> | TreeNode<any>[] | null;

  ngOnChanges() {
    const layers = [...this.layers];
    this.treeLayers = layers.map((layer, i) => ({
      layerIndex: i,
      label: layer.name || 'Layer',
      icon: '',
      children: layer.blocks.map((block, j) => {
        const data = BLOCKS_BUILDER.find((item) => item.value === block.type);
        return {
          layerIndex: i,
          blockIndex: j,
          label: data?.name,
          icon: data?.icon,
        };
      }),
    }));
  }

  onSelectionLayer(event: TreeNode<any> | TreeNode<any>[] | null) {
    console.log(event);
  }
}
