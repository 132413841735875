<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [header]="'Select product'"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ 'min-width': '860px' }"
  contentStyleClass="!pb-1"
>
  <!-- <div class="flex gap-2">
    <div class="pb-4 w-full flex flex-col gap-2">
      <label class="text-sm">
        {{ "common.categories" | translate | sentenceCase }}
        <span class="text-orange-dark-500 text-sm">*</span>
      </label>
      <p-treeSelect
        [metaKeySelection]="false"
        [(ngModel)]="categories"
        [ngModelOptions]="{ standalone: true }"
        [options]="_categories"
        placeholder="All categories"
        containerStyleClass="w-full"
        selectionMode="multiple"
        display="chip"
        (ngModelChange)="_fetchProducts(true)"
      >
        <ng-template pTemplate="value">
          <div *ngIf="categories.length == 0">All categories</div>
          <div>
            <ul class="flex flex-wrap gap-2">
              <li
                class="flex items-center justify-center px-3 py-1 rounded-full gap-2 bg-gray-200"
                *ngFor="let category of categories; let i = index"
              >
                <span>{{ category.label }}</span>
                <span
                  (click)="onRemoveCategory($event, i)"
                  [class]="SoctripIcons.X_CLOSE"
                ></span>
              </li>
            </ul>
          </div>
        </ng-template>
      </p-treeSelect>
    </div>
  </div> -->

  <span class="p-input-icon-left w-2/5 mb-3 mt-1">
    <i class="pi pi-search"></i>
    <input
      [(ngModel)]="keyword"
      (ngModelChange)="keywordChanged.next($event)"
      class="h-9 !text-sm w-full rounded-lg"
      type="text"
      pInputText
      [placeholder]="'Search by product name'"
    />
  </span>

  <div class="border rounded-lg overflow-hidden relative">
    <div
      *ngIf="isFetching"
      class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
    ></div>

    <p-table
      dataKey="id"
      scrollHeight="calc(100vh - 410px)"
      [scrollable]="true"
      [selectionPageOnly]="true"
      [selection]="selectedProducts"
      [value]="_products"
      [tableStyle]="{ width: '100%' }"
      (selectionChange)="onChangeSelectedProducts($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>
            {{ "Product name" | translate | sentenceCase }}
          </th>
          <th>
            {{ "Product ID" | translate | sentenceCase }}
          </th>
          <th>
            {{ "Rating" | translate | sentenceCase }}
          </th>
          <th class="!text-right">
            {{ "Unit sold" | translate | sentenceCase }}
          </th>
          <th class="!text-right">
            {{ "common.price" | translate | sentenceCase }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>
            <p-tableCheckbox
              [value]="{
                id: product.id,
                name: product.name,
                avatar: product.avatar.id
              }"
            ></p-tableCheckbox>
          </td>
          <td class="text-sm !font-medium">
            <div class="flex items-center gap-2">
              <app-custom-image
                class="min-w-[40px] w-10 h-10 overflow-hidden rounded-full"
                [imgId]="product?.avatar?.id"
                [alt]="'common.product' | translate | sentenceCase"
              >
              </app-custom-image>
              <div class="flex flex-col gap-0.5">
                <span class="line-clamp-2 max-w-[400px]">
                  {{ product.name }}
                </span>
              </div>
            </div>
          </td>
          <td>34034474</td>
          <td>
            <div class="flex items-center gap-[6px]">
              <img src="/assets/icons/stars.svg" alt="Start" />
              <span>{{ product?.point?.toFixed(1) ?? 0 }}</span>
            </div>
          </td>
          <td class="text-sm !font-medium">11</td>
          <td class="text-sm !font-medium !text-right">
            {{ product.original_price_after_tax | currency }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div
          *ngIf="_products.length === 0 && isFetched"
          class="bg-white w-full flex flex-col items-center py-4"
        >
          <img
            class="h-[60px]"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.empty-data' | translate | sentenceCase"
          />
          <span class="text-gray-300 mt-2 text-xs">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <app-paginator
      (onChange)="_fetchProducts(false)"
      [(pagination)]="pagination"
      [totalRecords]="_totalProduct"
      [dataLength]="_products.length"
      [isCompact]="true"
      [stylesClass]="'!p-0'"
    ></app-paginator>

    <div
      [ngClass]="{ 'pt-4': _totalProduct <= 5 }"
      class="w-full flex justify-end gap-1"
    >
      <app-button
        [label]="'section-action.cancel' | translate | sentenceCase"
        variant="outline-secondary"
        size="lg"
        (onClick)="onCancelAddProduct()"
      ></app-button>
      <app-button
        [label]="'section-action.save' | translate | sentenceCase"
        size="lg"
        (onClick)="onAddProducts()"
      ></app-button>
    </div>
  </ng-template>
</p-dialog>
