import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'landing-page',
    title: 'landing-page',
    icon: SoctripIcons.FILE_HEART_03,
  },
];
