import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private isMenuShowSubject = new BehaviorSubject(true);

  public isMenuShow$ = this.isMenuShowSubject.asObservable();

  setIsMenuShow(value: boolean) {
    this.isMenuShowSubject.next(value);
  }
}
