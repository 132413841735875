import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';
import {
  BLOCKS_BUILDER,
  IMAGE_ALIGNS,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { BLOCK_TYPE, TAB_MEDIA } from 'src/app/core/enum/marketing-builder';
import { FormatBytesPipe } from 'src/app/core/pipes/format-bytes.pipe';

@Component({
  selector: 'app-right-bar-builder',
  templateUrl: './right-bar-builder.component.html',
  styleUrls: ['./right-bar-builder.component.scss'],
  providers: [FormatBytesPipe],
})
export class RightBarBuilderComponent {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  @Output() layersChange = new EventEmitter<Layer[]>();

  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly IMAGE_ALIGNS = IMAGE_ALIGNS;
  readonly SoctripIcons = SoctripIcons;
  readonly DEVICES = [
    {
      name: 'Desktop',
      value: TAB_MEDIA.MEDIA1,
    },
    {
      name: 'Mobile',
      value: TAB_MEDIA.MEDIA2,
    },
  ];
  readonly TYPES = [
    {
      name: 'Default',
      value: TAB_MEDIA.MEDIA1,
    },
    {
      name: 'Selected',
      value: TAB_MEDIA.MEDIA2,
    },
  ];

  constructor(
    private fileService: FileService,
    private formatBytesPipe: FormatBytesPipe,
  ) {}

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  get layerData() {
    return this.layers[this.elementSelected.layerIndex];
  }

  getTitleRightBar(): string {
    if (this.elementSelected.scope === 'LAYER') {
      return 'Layer';
    } else {
      const type = this.blockData.type;
      if (type) {
        return BLOCKS_BUILDER.find((item) => item.value === type)?.name || '';
      } else {
        return '';
      }
    }
  }

  onUploadImage({ data, tab }: { data: FileList; tab: string }) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;
    const file = data?.[0];

    if (!file || this.layers[layerIndex].blocks[blockIndex].medias.length === 0)
      return;

    this.layers[layerIndex].blocks[blockIndex].medias[0].isLoading = true;
    this.fileService.uploadFile(file).subscribe({
      next: (res) => {
        const attachment = {
          id: res.data.id,
          name: res.data.original_name,
          size: this.formatBytesPipe.transform(res.data.size),
        };

        this.layers[layerIndex].blocks[blockIndex].medias[0] = {
          ...this.layers[layerIndex].blocks[blockIndex].medias[0],
          ...(tab === TAB_MEDIA.MEDIA1
            ? { media1: attachment }
            : { media2: attachment }),
          isLoading: false,
        };
      },
      error: () => {
        this.layers[layerIndex].blocks[blockIndex].medias[0].isLoading = false;
      },
    });
  }

  onDeleteImage(event: string) {
    const { layerIndex, blockIndex } = this.elementSelected;

    this.layers[layerIndex].blocks[blockIndex].medias[0] = {
      ...this.layers[layerIndex].blocks[blockIndex].medias[0],
      ...(event === TAB_MEDIA.MEDIA1
        ? { media1: undefined }
        : { media2: undefined }),
    };
  }

  onUploadMediaByIndex(
    { data, tab }: { data: FileList; tab: string },
    bannerIndex: number,
  ) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const file = data?.[0];
    if (
      !file ||
      !this.layers[layerIndex].blocks[blockIndex].medias?.[bannerIndex]
    )
      return;

    this.layers[layerIndex].blocks[blockIndex].medias[bannerIndex].isLoading =
      true;

    this.fileService.uploadFile(file).subscribe({
      next: (res) => {
        const attachment = {
          id: res.data.id,
          name: res.data.original_name,
          size: this.formatBytesPipe.transform(res.data.size),
        };

        const media =
          this.layers[layerIndex].blocks[blockIndex].medias[bannerIndex];
        if (tab === TAB_MEDIA.MEDIA1) {
          media.media1 = attachment;
        } else {
          media.media2 = attachment;
        }

        media.isLoading = false;
      },
      error: () => {
        this.layers[layerIndex].blocks[blockIndex].medias[
          bannerIndex
        ].isLoading = false;
      },
    });
  }

  onDeleteMediaByIndex(tab: string, bannerIndex: number) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    if (this.layers[layerIndex].blocks[blockIndex].medias?.[bannerIndex]) {
      const medias = [
        ...(this.layers[layerIndex].blocks[blockIndex].medias || []),
      ];

      if (tab === TAB_MEDIA.MEDIA1) {
        medias[bannerIndex].media1 = undefined;
      } else {
        medias[bannerIndex].media2 = undefined;
      }

      this.layers[layerIndex].blocks[blockIndex].medias = [...medias];
    }
  }

  onAddMedia(blockType: BLOCK_TYPE) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const currentMedias = this.layers[layerIndex].blocks[blockIndex].medias;

    if (
      (blockType === BLOCK_TYPE.SLIDE && currentMedias.length < 20) ||
      (blockType === BLOCK_TYPE.TAB && currentMedias.length < 10)
    ) {
      const medias = [...(currentMedias || []), {}];
      this.layers[layerIndex].blocks[blockIndex].medias = [...medias];
    }
  }

  onChangeAlignImage(value: string) {
    const { layerIndex, blockIndex } = this.elementSelected;

    this.layers[layerIndex].blocks[blockIndex].medias[0] = {
      ...this.layers[layerIndex].blocks[blockIndex].medias[0],
      alignment: value,
    };
  }

  onUpdateLayers() {
    this.layers = [...this.layers];
    this.layersChange.emit(this.layers);
  }

  onUploadVideo(files: FileList) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const file = files?.[0];

    if (!file || this.layers[layerIndex].blocks[blockIndex].medias.length === 0)
      return;

    this.layers[layerIndex].blocks[blockIndex].medias[0].isLoading = true;
    this.fileService.uploadFile(file).subscribe({
      next: (res) => {
        const attachment = {
          id: res.data.id,
          name: res.data.original_name,
          size: this.formatBytesPipe.transform(res.data.size),
          
        };

        this.layers[layerIndex].blocks[blockIndex].medias[0] = {
          ...this.layers[layerIndex].blocks[blockIndex].medias[0],
          media1: attachment,
          isLoading: false,
        };
      },

      error: () => {
        this.layers[layerIndex].blocks[blockIndex].medias[0].isLoading = false;
      },
    });
  }

  onDeleteVideo() {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    if (this.layers[layerIndex].blocks[blockIndex].medias) {
      this.layers[layerIndex].blocks[blockIndex].medias[0].media1 = undefined;
    }
  }
}
