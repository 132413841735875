import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ElementSelected, Layer } from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-link-image',
  templateUrl: './link-image.component.html',
  styleUrls: ['./link-image.component.scss'],
})
export class LinkImageComponent {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  readonly SoctripIcons = SoctripIcons;
  isExpand: boolean = true;

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  onToggleExpand() {
    this.isExpand = !this.isExpand;
  }
}
