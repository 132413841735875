import { Component, Input } from '@angular/core';
import { InputNumberInputEvent } from 'primeng/inputnumber';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-setup-vouchers',
  templateUrl: './setup-vouchers.component.html',
  styleUrls: ['./setup-vouchers.component.scss'],
})
export class SetupVouchersComponent {
  readonly SoctripIcons = SoctripIcons;

  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  isModalVoucher: boolean = false;

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  get maxVoucher() {
    return this.blockData.line * this.blockData.itemPerLine;
  }

  onLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 1) {
      this.blockData.line = 1;
    } else if (inputValue > 5) {
      this.blockData.line = 5;
    }
  }

  onItemPerLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 4) {
      this.blockData.itemPerLine = 4;
    } else if (inputValue > 6) {
      this.blockData.itemPerLine = 6;
    }
  }

  onMoveUpSort(index: number) {
    const temp = this.blockData.vouchers[index];

    this.blockData.vouchers[index] = { ...this.blockData.vouchers[index - 1] };
    this.blockData.vouchers[index - 1] = { ...temp };
  }

  onMoveDownSort(index: number) {
    const temp = this.blockData.vouchers[index];

    this.blockData.vouchers[index] = { ...this.blockData.vouchers[index + 1] };
    this.blockData.vouchers[index + 1] = { ...temp };
  }

  onDeleteVoucher(id: string) {
    this.blockData.vouchers = [...this.blockData.vouchers].filter(
      (item) => item.id !== id,
    );
  }

  onToggleAddVoucher() {
    if (this.maxVoucher > this.blockData.vouchers.length) {
      this.isModalVoucher = !this.isModalVoucher;
    }
  }
}
