import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { BlockEntityData } from 'src/app/core/models/interfaces/marketing-builder';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-shops-modal',
  templateUrl: './add-shops-modal.component.html',
  styleUrls: ['./add-shops-modal.component.scss'],
})
export class AddShopsModalComponent implements OnInit {
  @Input({ required: true }) isModal = false;
  @Input() selectedShops: BlockEntityData[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedShopsChange = new EventEmitter<BlockEntityData[]>();

  isLoading: boolean = false;
  isFetched: boolean = false;
  COLS_QUANTITY_LOADING = Array(4).fill(1);
  ROWS_QUANTITY_LOADING = Array(5).fill(5);
  pagination = {
    rows: 10,
    page: 0,
  };
  shops = [];
  _totalElement: number;
  keyword = '';
  keywordChanged = new Subject<string>();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.keywordChanged.pipe(debounceTime(600)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this.resetPaginator();
        this._initShops();
      },
    });
    this._initShops();
  }

  resetPaginator() {
    this.pagination = {
      rows: 10,
      page: 0,
    };
  }

  _initShops() {
    this.isLoading = true;
    const searchFilter = this.keyword ? `name@=${this.keyword}` : '';
    this.http
      .get(
        `${environment.BE_URL}shop/shops?pageNum=${
          this.pagination.page
        }&pageSize=${this.pagination.rows}&filters=${encodeURIComponent(
          searchFilter,
        )}`,
      )
      .subscribe({
        next: (res: any) => {
          this.shops = res?.data?.data;
          this._totalElement = res?.data?.totalElement;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isFetched = true;
          this.isLoading = false;
        },
      });
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
    this.keywordChanged.next('');
  }

  onCancelAddShop() {
    this.setIsModal(false);
  }

  onAddShops() {
    this.selectedShopsChange.emit(this.selectedShops);
    this.setIsModal(false);
  }
}
