<div class="p-4 h-[calc(100vh-60px)] sticky top-[60px]">
  <div
    class="h-full bg-white min-h-full border shadow-md py-4 rounded-xl w-[370px] overflow-y-auto"
  >
    <h2 class="px-5 pb-4 font-semibold border-b">{{ getTitleRightBar() }}</h2>

    <div [ngSwitch]="elementSelected.scope">
      <!-- LAYER -->
      <div *ngSwitchCase="'LAYER'" class="px-5 pb-5 border-b">
        <h3 class="py-4 font-semibold text-sm">General Information</h3>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-1">
            <label class="font-medium text-sm">Title</label>
            <input
              class="h-9"
              pInputText
              [(ngModel)]="layerData.name"
              placeholder="Enter layer name"
              (input)="onUpdateLayers()"
            />
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex gap-1 items-center text-sm">
              <span class="font-medium">
                Description
                <span class="font-normal text-gray-500">({{ layerData.description.length }}/120)</span>
              </span>
            </div>
            <textarea
                id="description"
                rows="5"
                cols="30"
                [(ngModel)]="layerData.description"
                placeholder="Enter description"
                maxlength="120"
                class="font-normal text-sm w-full mt-2 p-2 border rounded-md border-gray-300 text-gray-500"
              ></textarea>
          </div>
        </div>
      </div>

      <!-- BLOCK -->
      <div *ngSwitchCase="'BLOCK'" [ngSwitch]="blockData.type">
        <!-- TEXT -->
        <div *ngSwitchCase="BLOCK_TYPE.TEXT" class="px-5 pb-5 border-b">
          <div class="py-4 font-semibold text-sm">General information</div>
          <div class="flex flex-col gap-1">
            <div class="flex gap-1 items-center text-sm">
              <span class="font-medium">Description</span>
              <span class="text-gray-500"
                >({{ blockData.description.length || 0 }}/120)</span
              >
            </div>
            <textarea
              placeholder="Enter description"
              pInputTextarea
              cols="5"
              [(ngModel)]="blockData.description"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>

        <!-- IMAGE -->
        <div *ngSwitchCase="BLOCK_TYPE.IMAGE">
          <div class="py-4 px-5 font-semibold text-sm">Upload image</div>

          <!-- Image -->
          <div class="px-5">
            <app-upload-image
              *ngIf="blockData.medias.length"
              [media1]="blockData.medias[0].media1"
              [media2]="blockData.medias[0].media2"
              [tabs]="DEVICES"
              [isLoading]="blockData.medias[0].isLoading"
              (uploadImage)="onUploadImage($event)"
              (deleteImage)="onDeleteImage($event)"
            ></app-upload-image>
          </div>

          <!-- Alignment -->
          <!-- <div
            class="px-5 py-2 flex items-center justify-between border-t mt-2"
          >
            <span class="text-sm font-semibold">Alignment</span>
            <div
              class="h-9 px-[2px] flex items-center gap-[2px] rounded-lg bg-gray-100"
            >
              <span
                class="h-8 w-8 flex items-center justify-center rounded-lg cursor-pointer hover:bg-white"
                [ngClass]="
                  blockData.data?.alignment === ALIGN.value ? 'bg-white' : ''
                "
                (click)="onChangeAlignImage(ALIGN.value)"
                *ngFor="let ALIGN of IMAGE_ALIGNS"
              >
                <i class="{{ ALIGN.icon }} text-xl"></i>
              </span>
            </div>
          </div> -->

          <!-- Link -->
          <app-link-image
            [layers]="layers"
            [elementSelected]="elementSelected"
          ></app-link-image>

          <!-- Effect -->
          <div class="px-5 py-2 flex items-center justify-between border-y">
            <span class="text-sm font-semibold">Effect</span>
            <div
              class="w-9 h-9 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-lg"
            >
              <i class="{{ SoctripIcons.PLUS }} text-xl"></i>
            </div>
          </div>
        </div>

        <!-- SLIDE -->
        <div *ngSwitchCase="BLOCK_TYPE.SLIDE">
          <div class="py-4 px-5 flex flex-col gap-1">
            <span class="font-semibold text-sm">Upload banner</span>
            <p class="text-sm text-gray-500">Add up to 20 banners</p>
          </div>

          <div class="px-5 flex flex-col gap-4 pb-1">
            <div
              class="p-4 border rounded-lg bg-gray-50"
              *ngFor="let media of blockData.medias; let i = index"
            >
              <div class="mb-3 text-xs font-medium">BANNER {{ i + 1 }}</div>

              <!-- Image -->
              <app-upload-image
                [media1]="media?.media1"
                [media2]="media?.media2"
                [isLoading]="media?.isLoading"
                [tabs]="DEVICES"
                (uploadImage)="onUploadMediaByIndex($event, i)"
                (deleteImage)="onDeleteMediaByIndex($event, i)"
              ></app-upload-image>

              <div class="flex flex-col gap-4">
                <input
                  pInputText
                  class="h-9 rounded-lg w-full"
                  placeholder="https://"
                  [(ngModel)]="media.link1"
                />

                <div class="flex items-center gap-2">
                  <p-checkbox
                    [binary]="true"
                    [(ngModel)]="media.isOpenNewTab"
                  ></p-checkbox>
                  <span class="font-medium text-sm">Open in new tab</span>
                </div>
              </div>
            </div>

            <app-button
              variant="outline-secondary"
              label="Add banner"
              (onClick)="onAddMedia(BLOCK_TYPE.SLIDE)"
            ></app-button>
          </div>
        </div>

        <!-- VIDEO -->
        <div *ngSwitchCase="BLOCK_TYPE.VIDEO">
          <div class="py-4 px-5 flex flex-col gap-1">
            <span class="font-semibold text-sm">Video</span>
          </div>

          <div class="px-5 flex flex-col gap-4 pb-1">
            <app-upload-video
              [isLoading]="blockData.medias[0].isLoading"
              [video]="blockData.medias[0].media1"
              [(linkYoutube)]="blockData.medias[0].link2"
              (uploadVideo)="onUploadVideo($event)"
              (deleteVideo)="onDeleteVideo()"
            ></app-upload-video>
          </div>
        </div>

        <!-- TAB -->
        <div *ngSwitchCase="BLOCK_TYPE.TAB">
          <div class="py-4 px-5 flex flex-col gap-4 border-b">
            <span class="font-semibold text-sm">Position</span>
            <div class="flex flex-col gap-4">
              <div class="flex items-center">
                <p-radioButton
                  name="isStick"
                  [value]="false"
                  [(ngModel)]="blockData.isStick"
                  inputId="scrollWithParent"
                ></p-radioButton>
                <label for="scrollWithParent" class="ml-2 text-sm font-medium"
                  >Scroll with parent</label
                >
              </div>
              <div class="flex items-center">
                <p-radioButton
                  name="isStick"
                  [value]="true"
                  [(ngModel)]="blockData.isStick"
                  inputId="sticky"
                ></p-radioButton>
                <label for="sticky" class="ml-2 text-sm font-medium"
                  >Sticky (stop at top edge)</label
                >
              </div>
            </div>
          </div>

          <div class="py-4 px-5 flex flex-col gap-1">
            <span class="font-semibold text-sm">Upload tab thumbnails</span>
            <p class="text-sm text-gray-500">Add up to 10 tabs</p>
          </div>

          <div class="px-5 flex flex-col gap-4 pb-1">
            <div
              class="p-4 border rounded-lg bg-gray-50"
              *ngFor="let media of blockData.medias; let i = index"
            >
              <div class="mb-3 text-xs font-medium">TAB {{ i + 1 }}</div>

              <!-- Image -->
              <app-upload-image
                [media1]="media?.media1"
                [media2]="media?.media2"
                [isLoading]="media?.isLoading"
                [tabs]="TYPES"
                (uploadImage)="onUploadMediaByIndex($event, i)"
                (deleteImage)="onDeleteMediaByIndex($event, i)"
              ></app-upload-image>

              <!-- Navigate to -->
              <div class="flex flex-col gap-1">
                <span class="font-medium text-sm">Navigate to</span>
                <p-dropdown
                  styleClass="h-9 flex w-full"
                  [options]="layers"
                  optionValue="id"
                  [(ngModel)]="media.navigateTo"
                >
                  <ng-template pTemplate="selectedItem" let-implicit>
                    <div>{{ implicit.name || "Layer" }}</div>
                  </ng-template>

                  <ng-template pTemplate="item" let-implicit>
                    <div>{{ implicit.name || "Layer" }}</div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>

            <app-button
              variant="outline-secondary"
              label="Add tab"
              (onClick)="onAddMedia(BLOCK_TYPE.TAB)"
            ></app-button>
          </div>
        </div>

        <!-- PRODUCT -->
        <div *ngSwitchCase="BLOCK_TYPE.PRODUCT_LIST">
          <app-setup-products
            [elementSelected]="elementSelected"
            [layers]="layers"
          ></app-setup-products>
        </div>

        <!-- VOUCHER -->
        <div *ngSwitchCase="BLOCK_TYPE.VOUCHER">
          <app-setup-vouchers
            [elementSelected]="elementSelected"
            [layers]="layers"
          ></app-setup-vouchers>
        </div>
      </div>
    </div>
  </div>
</div>
