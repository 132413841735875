import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
})
export class EditorToolbarComponent {
  @Input() toolbar: string[] = ['HEADING', 'TEXT_DECORATION', 'INSERT', 'LIST'];

  isShowButton(action: string) {
    return this.toolbar.includes(action);
  }
}
