import { BLOCK_TYPE, PRODUCT_LIST_FILTER } from '../enum/marketing-builder';
import { SoctripIcons } from './soctrip-icon.enum';

export const BLOCKS_BUILDER = [
  {
    name: 'Text',
    value: BLOCK_TYPE.TEXT,
    icon: SoctripIcons.TYPE_01,
  },
  {
    name: 'Image',
    value: BLOCK_TYPE.IMAGE,
    icon: SoctripIcons.IMAGE_03,
  },
  {
    name: 'Slide',
    value: 'SLIDE',
    icon: SoctripIcons.COLUMNS_02,
  },
  {
    name: 'Video',
    value: 'VIDEO',
    icon: SoctripIcons.VIDEO_RECORDER,
  },
  {
    name: 'Tab',
    value: 'TAB',
    icon: SoctripIcons.PASSCODE,
  },
  {
    name: 'Product list',
    value: 'PRODUCT_LIST',
    icon: SoctripIcons.PACKAGE,
  },
  {
    name: 'Voucher',
    value: 'VOUCHER',
    icon: SoctripIcons.TICKET_01,
  },
];

export const LAYOUTS_BUILDER = [
  {
    code: 'ONE_ONE',
    value: [1],
  },
  {
    code: 'TWO_TWO',
    value: [1, 1],
  },
  {
    code: 'THREE_ONE',
    value: [1, 1, 1],
  },
  {
    code: 'FOUR_ONE',
    value: [1, 1, 1, 1],
  },
  {
    code: 'FIVE_ONE',
    value: [1, 1, 1, 1, 1],
  },
  {
    code: 'TWO_TWO_THREE',
    value: [1, 2],
  },
  {
    code: 'TWO_THREE_TWO',
    value: [2, 1],
  },
];

export const IMAGE_ALIGNS = [
  {
    value: 'LEFT',
    icon: SoctripIcons.ALIGN_LEFT,
  },
  {
    value: 'CENTER',
    icon: SoctripIcons.ALIGN_CENTER,
  },
  {
    value: 'RIGHT',
    icon: SoctripIcons.ALIGN_RIGHT,
  },
];

export const FILTERS_PRODUCT_BY = [
  {
    name: 'Promotion programs',
    value: 'PROMOTION',
  },
  {
    name: 'Keywords',
    value: 'KEYWORD',
  },
];

export const SORT_PRODUCTS = [
  {
    name: 'Name',
    value: 'name|string',
    scope: 'string',
    disabled: false,
  },
  {
    name: 'Price',
    value: 'price|Double',
    scope: 'number',
    disabled: false,
  },
  {
    name: 'Discount',
    value: 'discount_percent|Double',
    scope: 'number',
    disabled: false,
  },
];

export const SORT_ITEMS = [
  {
    scope: 'string',
    name: 'A to Z',
    value: 1,
  },
  {
    scope: 'string',
    name: 'Z to A',
    value: -1,
  },
  {
    scope: 'number',
    name: 'High to low',
    value: 1,
  },
  {
    scope: 'number',
    name: 'Low to high',
    value: -1,
  },
];

export const PRODUCT_LIST_FILTERS_BY = [
  {
    name: 'Promotion programs',
    value: PRODUCT_LIST_FILTER.PROMOTION,
  },
  {
    name: 'Keywords',
    value: PRODUCT_LIST_FILTER.KEYWORD,
  },
  {
    name: 'Categories',
    value: PRODUCT_LIST_FILTER.CATEGORY,
  },
  {
    name: 'Product rating',
    value: PRODUCT_LIST_FILTER.PRODUCT_RATING,
  },
  {
    name: 'Cities (Shop location)',
    value: PRODUCT_LIST_FILTER.CITY,
  },
  {
    name: 'Shop',
    value: PRODUCT_LIST_FILTER.SHOP,
  },
  {
    name: 'Price range',
    value: PRODUCT_LIST_FILTER.PRICE_RANGE,
  },
  {
    name: 'Specific product',
    value: PRODUCT_LIST_FILTER.PRODUCT,
  },
];

export const STARS = [1, 2, 3, 4, 5];
