import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  user: any;

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  ngOnInit() {
    this.checkSmallScreen();
  }
}
