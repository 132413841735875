import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { Attachment } from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  @Input({ required: true }) tabs: Array<{ name: string; value: string }> = [];
  @Input() media1?: Attachment;
  @Input() media2?: Attachment;
  @Input() isLoading?: boolean = false;

  @Output() uploadImage = new EventEmitter<{
    data: FileList;
    tab: string;
  }>();
  @Output() deleteImage = new EventEmitter<string>();

  readonly SoctripIcons = SoctripIcons;
  tabActive: string = '';

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    if (this.tabs.length) {
      this.tabActive = this.tabs[0].value;
    }
  }

  get image() {
    return this.tabActive === this.tabs[0].value ? this.media1 : this.media2;
  }

  onSelectImage(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.onEmitFiles(files);
  }

  onSelectVideo(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.onEmitFiles(files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;

    this.onEmitFiles(files);
  }

  onEmitFiles(files?: FileList | null) {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
    if (files) {
      const size = files[0].size / 1024 / 1024;
      const type = files[0].type;

      if (!allowedTypes.includes(type)) {
        this.messageService.add({
          severity: 'error',
          detail: 'File type is not supported',
        });
        return;
      }

      if (size > 3) {
        this.messageService.add({
          severity: 'error',
          detail: 'File size is too large',
        });
        return;
      }

      this.uploadImage.emit({
        data: files,
        tab: this.tabActive,
      });
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }
}
