import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { TAB_MEDIA } from 'src/app/core/enum/marketing-builder';
import { Attachment } from 'src/app/core/models/interfaces/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss'],
})
export class UploadVideoComponent {
  @Input() linkYoutube?: string = '';
  @Input() video?: Attachment;
  @Input() isLoading?: boolean = false;

  @Output() uploadVideo = new EventEmitter<FileList>();
  @Output() deleteVideo = new EventEmitter<TAB_MEDIA>();
  @Output() linkYoutubeChange = new EventEmitter<string>();

  readonly SoctripIcons = SoctripIcons;
  readonly TAB_MEDIA = TAB_MEDIA;
  readonly TYPES = [
    {
      name: 'Upload',
      value: TAB_MEDIA.MEDIA1,
    },
    {
      name: 'Youtube link',
      value: TAB_MEDIA.MEDIA2,
    },
  ];

  type: TAB_MEDIA = TAB_MEDIA.MEDIA1;

  constructor(
    public fileService: FileService,
    private messageService: MessageService,
  ) {}

  onSelectVideo(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.onEmitFiles(files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;

    this.onEmitFiles(files);
  }

  onEmitFiles(files?: FileList | null) {
    const allowedTypes = ['video/mp4', 'video/x-msvideo'];
    if (files) {
      const size = files[0].size / 1024 / 1024;
      const type = files[0].type;

      if (!allowedTypes.includes(type)) {
        this.messageService.add({
          severity: 'error',
          detail: 'File type is not supported',
        });
        return;
      }

      if (size > 10) {
        this.messageService.add({
          severity: 'error',
          detail: 'File size is too large',
        });
        return;
      }

      this.uploadVideo.emit(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }
}
