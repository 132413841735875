import { OnChanges, OnInit } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { EventEmitter, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { ShopCatalogControllerService } from '@soctrip/angular-catalog-service';
import { convertCategoriesToTree } from 'src/app/core/utils/convert-categories';
import { StockProductControllerService } from '@soctrip/angular-stock-service';
import { ProductDetails } from 'src/app/core/models/interfaces/product';
import { BlockEntityData } from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
export class AddProductModalComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedProducts: BlockEntityData[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedProductsChange = new EventEmitter<any[]>();
  @Output() totalProduct = new EventEmitter<number>();

  _products: ProductDetails[] = [];
  _totalProduct = 0;
  isFetching = false;
  isFetched = false;

  _categories: any[] = [];
  categories: any[] = [];
  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChanged = new Subject<string>();

  constructor(
    private stockProductService: StockProductControllerService,
    private shopCatalogService: ShopCatalogControllerService,
    public fileService: FileService,
  ) {}

  ngOnInit() {
    this.keywordChanged.pipe(debounceTime(800)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this._fetchProducts(true);
      },
    });

    this._fetchProducts(true);
  }

  _fetchCategories() {}

  _fetchProducts(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    if (this.isFetched) {
      this.isFetching = true;
    }

    const catalog_ids = this.categories
      ?.map((category) => category.id)
      .join('|');
    const filters = [
      catalog_ids ? `catalog_ids==${catalog_ids}` : '',
      this.keyword ? `name@=${this.keyword}` : '',
    ]
      .filter((i) => i)
      .join(',');

    this.stockProductService
      .productsSearchMarketingHubGet(
        filters,
        this.pagination.page,
        this.pagination.rows,
      )
      .subscribe({
        next: (res: any) => {
          this._products = res.data.data;
          this._totalProduct = res.data.totalElement;
          this.totalProduct.emit(this._totalProduct);
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
        error: () => {
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
      });
  }

  onRemoveCategory(event: any, index: number) {
    event.stopPropagation();
    this.categories.splice(index, 1);
    this._fetchProducts(true);
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
  }

  onChangeSelectedProducts(e: any) {
    this.selectedProducts = e;
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedProductsChange.emit(this.selectedProducts);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }
}
