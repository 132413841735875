import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropdownModule } from 'primeng/dropdown';
import {
  ApiModule as PromotionApiModule,
  BASE_PATH as PROMOTION_PATH,
} from '@soctrip/angular-promotion-service';
import {
  ApiModule as AdvertisingApiModule,
  BASE_PATH as ADVERTISING_PATH,
} from '@soctrip/angular-advertising-service';
import {
  ApiModule as StockApiModule,
  BASE_PATH as STOCK_PATH,
} from '@soctrip/angular-stock-service';
import {
  ApiModule as CatalogApiModule,
  BASE_PATH as CATALOG_PATH,
} from '@soctrip/angular-catalog-service';
import {
  ApiModule as ShipmentApiModule,
  BASE_PATH as SHIPMENT_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as ShopApiModule,
  BASE_PATH as SHOP_PATH,
} from '@soctrip/angular-shop-service';
import {
  ApiModule as MarketingHubApiModule,
  BASE_PATH as MARKETING_HUB_PATH,
} from '@soctrip/angular-marketing-hub-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { SharedModule } from './shared/shared.module';

import { Locales } from './core/constants/locales.config';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PromotionApiModule,
    AdvertisingApiModule,
    StockApiModule,
    CatalogApiModule,
    ShipmentApiModule,
    MarketingHubApiModule,
    ShopApiModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    {
      provide: PROMOTION_PATH,
      useValue: `${environment.BE_URL + 'promotion'}`,
    },
    {
      provide: ADVERTISING_PATH,
      useValue: `${environment.BE_URL + 'advertising'}`,
    },
    {
      provide: STOCK_PATH,
      useValue: `${environment.BE_URL + 'stock'}`,
    },
    {
      provide: CATALOG_PATH,
      useValue: `${environment.BE_URL + 'catalog'}`,
    },
    {
      provide: SHIPMENT_PATH,
      useValue: `${environment.BE_URL + 'shipment'}`,
    },
    {
      provide: SHOP_PATH,
      useValue: `${environment.BE_URL + 'shop'}`,
    },
    {
      provide: MARKETING_HUB_PATH,
      useValue: `${environment.BE_URL + 'marketing-hub'}`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
