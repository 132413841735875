import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { SidebarLayoutComponent } from './shared/layouts/sidebar-layout/sidebar-layout.component';
import { WelcomeLayoutComponent } from './shared/layouts/welcome-layout/welcome-layout.component';
import { LandingPageBuilderComponent } from './modules/landing-page/pages/landing-page-builder/landing-page-builder.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeLayoutComponent,
    pathMatch: 'full',
  },

  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'landing-page/builder/:id',
        component: LandingPageBuilderComponent,
      },
      {
        path: '',
        component: SidebarLayoutComponent,
        children: [
          {
            path: 'landing-page',
            loadChildren: () =>
              import('./modules/landing-page/landing-page.module').then(
                (m) => m.LandingPageModule,
              ),
          },
          {
            path: '**',
            redirectTo: 'landing-page',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
