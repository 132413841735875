<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [header]="'Select shop'"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ 'min-width': '860px' }"
  contentStyleClass="!pb-1"
>
  <div
    *ngIf="isFetched && isLoading"
    class="absolute z-10 flex top-4 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
  >
    <p-progressSpinner styleClass="w-[1rem] h-[1rem]"></p-progressSpinner>
  </div>

  <span class="p-input-icon-left w-2/5 mb-3 mt-1">
    <i class="pi pi-search"></i>
    <input
      pInputText
      type="text"
      [(ngModel)]="keyword"
      (ngModelChange)="this.keywordChanged.next($event)"
      [placeholder]="'Search by shop name'"
      class="w-full h-9 rounded-lg"
    />
  </span>

  <div class="border rounded-lg overflow-hidden">
    <p-table
      #dt
      [value]="shops"
      dataKey="id"
      [loading]="!isFetched"
      [(selection)]="selectedShops"
      class="relative"
      [scrollable]="true"
      scrollHeight="calc(100vh - 410px)"
      [selectionPageOnly]="true"
    >
      <ng-template pTemplate="loadingbody">
        <tr *ngFor="let row of ROWS_QUANTITY_LOADING">
          <td *ngFor="let col of COLS_QUANTITY_LOADING">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header" class="sticky top-0">
        <tr>
          <th>
            <p-tableHeaderCheckbox />
          </th>
          <th>
            {{ "Shop name" }}
          </th>
          <th>
            {{ "Rating" }}
          </th>
          <th>
            {{ "Total products" }}
          </th>
          <th>
            {{ "Location" }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shop>
        <tr class="p-selectable-row">
          <td>
            <p-tableCheckbox
              [value]="{
                id: shop.id,
                name: shop.name,
                avatar: shop.avatar?.id
              }"
            ></p-tableCheckbox>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <app-custom-image
                [imgId]="shop?.avatar?.id"
                alt="avatar"
                class="min-w-[40px] w-10 h-10 rounded-full overflow-hidden"
              >
              </app-custom-image>

              <div class="flex flex-col gap-1">
                <span class="text-sm font-medium text-gray-600">{{
                  shop.name
                }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center gap-[6px]">
              <img src="/assets/icons/stars.svg" alt="Start" />
              <span>{{ shop?.rating?.toFixed(1) ?? 0 }}</span>
            </div>
          </td>
          <td>
            <span class="ml-1 vertical-align-middle">{{ 10 }}</span>
          </td>

          <td>4140 Parker Rd. Allentown, New Mexico 31134</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div
          *ngIf="shops.length === 0 && isFetched"
          class="bg-white w-full flex flex-col gap-2 items-center py-4"
        >
          <img
            class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.no-data' | translate | sentenceCase"
          />
          <span class="text-gray-400 text-sm">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <app-paginator
      [stylesClass]="'!p-0'"
      [totalRecords]="_totalElement"
      [dataLength]="shops.length"
      [(pagination)]="pagination"
      (onChange)="_initShops()"
    ></app-paginator>
    <div
      [ngClass]="{ 'pt-4': _totalElement <= 5 }"
      class="w-full flex justify-end gap-1"
    >
      <app-button
        [label]="'section-action.cancel' | translate | sentenceCase"
        size="lg"
        variant="outline-secondary"
        (onClick)="onCancelAddShop()"
      ></app-button>

      <app-button
        size="lg"
        [label]="'section-action.add' | translate | sentenceCase"
        (onClick)="onAddShops()"
      ></app-button>
    </div>
  </ng-template>
</p-dialog>
