<app-header-builder
  [(mode)]="mode"
  [(marketingName)]="marketingName"
  [(updatedAt)]="updatedAt"
  [(previewDevice)]="previewDevice"
  [layers]="layers"
  [campaignId]="campaignId"
></app-header-builder>

<div class="pt-[60px] bg-gray-100">
  <div
    *ngIf="mode === 'BUILDER'; else previewTemplate"
    class="flex min-h-[calc(100vh-112px)]"
  >
    <!-- LEFT -->
    <app-left-bar-builder
      [elementSelected]="elementSelected"
      [layers]="layers"
    ></app-left-bar-builder>

    <!-- CENTER -->
    <div
      class="w-[calc(100vw-784px)] pb-14 mt-8 px-12 overflow-x-auto overflow-y-hidden"
    >
      <div
        *ngIf="isLoading"
        class="h-[calc(100vh-94px)] bg-white flex items-center justify-center"
      >
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf="!isLoading" class="bg-white min-w-[960px]">
        <app-soctrip-header></app-soctrip-header>

        <div
          class="layer-list"
          cdkDropList
          (cdkDropListDropped)="onDropLayer($event)"
        >
          <div
            *ngFor="let layer of layers; let layerIndex = index"
            cdkDrag
            class="layer-item relative border px-10 py-6 bg-white"
            [style.backgroundColor]="layer.bgColor"
            [ngClass]="[
              layerIndex === elementSelected.layerIndex &&
              elementSelected.scope === 'LAYER'
                ? 'border-gray-600'
                : 'border-transparent'
            ]"
            (click)="onClickLayer($event, layerIndex)"
          >
            <div class="flex flex-col gap-4">
              <h1
                *ngIf="layer.name"
                [style.color]="layer.textColor"
                class="text-xl font-semibold"
              >
                {{ layer.name }}
              </h1>

              <p-editor
                *ngIf="layer.description"
                styleClass="editor-preview"
                [style.color]="layer.textColor"
                [style.backgroundColor]="layer.bgColor"
                [readonly]="true"
                [ngModel]="layer.description"
              ></p-editor>

              <div
                class="flex items-center gap-4 max-w-full"
                *ngIf="layer.blocks.length; else dropLayerTemplate"
              >
                <div
                  class="max-w-full"
                  [style.flex]="block.flex"
                  *ngFor="let block of layer.blocks; let blockIndex = index"
                >
                  <!-- DROP BLOCK -->
                  <div
                    *ngIf="!block.type; else blockTemplate"
                    class="flex flex-col gap-1 items-center justify-center py-6 bg-primary-50 border border-dashed border-primary-600 text-primary-600"
                    dndDropzone
                    dndDragoverClass="!bg-primary-600/10"
                    (dndDrop)="onDropBlock($event, layerIndex, blockIndex)"
                  >
                    <i class="{{ SoctripIcons.DOWNLOAD_02 }}  text-xl"></i>
                    <div class="text-sm">Drop block here</div>
                  </div>

                  <!-- BLOCK -->
                  <ng-template #blockTemplate>
                    <div
                      class="border"
                      [ngClass]="[
                        layerIndex === elementSelected.layerIndex &&
                        blockIndex === elementSelected.blockIndex &&
                        elementSelected.scope === 'BLOCK'
                          ? 'border-gray-600'
                          : 'border-transparent'
                      ]"
                      (click)="onClickBlock($event, layerIndex, blockIndex)"
                      [ngSwitch]="block.type"
                    >
                      <!-- TEXT -->
                      <div
                        *ngSwitchCase="BLOCK_TYPE.TEXT"
                        [style]="{ 'word-break': 'break-word' }"
                      >
                        <p-editor
                          [(ngModel)]="block.text"
                          [style]="{ height: '450px' }"
                        >
                          <ng-template pTemplate="header">
                            <app-editor-toolbar></app-editor-toolbar>
                          </ng-template>
                        </p-editor>
                      </div>

                      <!-- IMAGE -->
                      <ng-container *ngSwitchCase="BLOCK_TYPE.IMAGE">
                        <ng-container *ngFor="let media of block.medias">
                          <div
                            class="bg-gray-100 min-h-[100px] text-gray-500 flex flex-col items-center justify-center gap-1"
                            *ngIf="!media?.media1"
                          >
                            <i class="{{ SoctripIcons.IMAGE_03 }} text-xl"></i>
                            <span class="text-sm">Image</span>
                          </div>
                          <app-custom-image
                            *ngIf="media?.media1?.id"
                            styleClass="min-h-[200px] flex items-center"
                            imageClass="w-[unset]"
                            [imgId]="media.media1?.id || ''"
                            alt="Image"
                          ></app-custom-image>
                        </ng-container>
                      </ng-container>

                      <!-- SLIDE -->
                      <div *ngSwitchCase="BLOCK_TYPE.SLIDE">
                        <p-galleria
                          *ngIf="block.medias.length > 0; else placeholderSlide"
                          [value]="block.medias"
                          [showItemNavigators]="true"
                          [showThumbnails]="false"
                          [showIndicators]="true"
                          [indicatorsPosition]="'bottom'"
                        >
                          <ng-template pTemplate="itempreviousicon">
                            <div
                              class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                            >
                              <i
                                class="{{
                                  SoctripIcons.CHEVRON_LEFT
                                }} text-white text-2xl"
                              ></i>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="itemnexticon">
                            <div
                              class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                            >
                              <i
                                class="{{
                                  SoctripIcons.CHEVRON_RIGHT
                                }} text-white text-2xl"
                              ></i>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="item" let-media>
                            <div
                              class="relative w-full h-0"
                              style="padding-bottom: 42.85%"
                            >
                              <app-custom-image
                                *ngIf="media.media1?.id; else placeholderSlide"
                                class="absolute inset-0 w-full h-full object-cover rounded-lg"
                                [imgId]="media.media1?.id"
                                alt="Slide"
                              />
                            </div>
                          </ng-template>
                        </p-galleria>

                        <ng-template #placeholderSlide>
                          <div
                            class="w-full h-[240px] bg-gray-100 flex flex-col gap-1 items-center justify-center"
                          >
                            <i
                              class="{{ SoctripIcons.COLUMNS_02 }} text-xl"
                            ></i>
                            <span class="text-gray-500 text-sm">Slide</span>
                          </div>
                        </ng-template>
                      </div>

                      <!-- VIDEO -->
                      <div *ngSwitchCase="BLOCK_TYPE.VIDEO">
                        <div
                          class="relative"
                          *ngIf="
                            block.medias[0].media1?.id ||
                              fileService.isYoutubeUrl(
                                block.medias[0]?.link2 || ''
                              );
                            else placeholderVideo
                          "
                        >
                          <img
                            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
                            src="/assets/icons/play.svg"
                            alt="Icon Play"
                          />
                          <!-- Thumbnail video -->
                          <div
                            *ngIf="block.medias[0].media1?.id"
                            class="relative w-full h-0"
                            style="padding-bottom: 42.85%"
                          >
                            <app-custom-image
                              class="absolute inset-0 w-full h-full object-cover rounded-lg"
                              [imgId]="block.medias[0].media1?.id || ''"
                              alt="Thumbnail Video"
                            />
                          </div>

                          <!-- Thumbnail youtube -->
                          <div
                            *ngIf="
                              !block.medias[0].media1?.id &&
                              block.medias[0]?.link2
                            "
                            class="relative w-full h-0"
                            style="padding-bottom: 42.85%"
                          >
                            <app-custom-image
                              class="absolute inset-0 w-full h-full object-cover rounded-lg"
                              [imgId]="block.medias[0].link2 || ''"
                              alt="Thumbnail Youtube"
                            />
                          </div>
                        </div>

                        <ng-template #placeholderVideo>
                          <div
                            class="h-[400px] bg-gray-100 flex flex-col gap-1 items-center justify-center"
                          >
                            <i
                              class="{{ SoctripIcons.VIDEO_RECORDER }} text-xl"
                            ></i>
                            <span class="text-gray-500 text-sm">Video</span>
                          </div>
                        </ng-template>
                      </div>

                      <!-- TAB -->
                      <div *ngSwitchCase="BLOCK_TYPE.TAB">
                        <div
                          *ngIf="block.medias.length; else placeholderTab"
                          class="flex gap-1 items-center"
                        >
                          <swiper-container
                            class="mySwiper"
                            space-between="4"
                            slides-per-view="auto"
                          >
                            <swiper-slide
                              *ngFor="let media of block.medias"
                              class="h-36 w-36 flex items-center"
                            >
                              <app-custom-image
                                [imgId]="media.media1?.id || ''"
                                alt="Tab"
                              ></app-custom-image>
                            </swiper-slide>
                          </swiper-container>
                        </div>

                        <ng-template #placeholderTab>
                          <div
                            class="h-24 bg-gray-100 flex flex-col gap-1 items-center justify-center"
                          >
                            <i class="{{ SoctripIcons.PASSCODE }} text-xl"></i>
                            <span class="text-gray-500 text-sm">Tab</span>
                          </div>
                        </ng-template>
                      </div>

                      <!-- PRODUCT LIST -->
                      <div *ngSwitchCase="BLOCK_TYPE.PRODUCT_LIST">
                        <div
                          class="grid gap-4 bg-gray-100 rounded-lg"
                          [ngStyle]="{
                            'grid-template-rows': getGridTemplate(block.line),
                            'grid-template-columns': getGridTemplate(
                              block.itemPerLine
                            )
                          }"
                        >
                          <div
                            *ngFor="
                              let item of generateArray(
                                block.itemPerLine * block.line
                              );
                              let i = index
                            "
                            class="h-[330px] rounded-lg border flex flex-col justify-between overflow-hidden bg-gray-50"
                          >
                            <div class="flex-1 h-[226px]">
                              <app-custom-image
                                *ngIf="
                                  block.products?.[i]?.avatar;
                                  else productDefaultTemplate
                                "
                                [imgId]="block.products?.[i]?.avatar?.id || ''"
                                [alt]="block.products?.[i]?.name || ''"
                              ></app-custom-image>
                              <ng-template #productDefaultTemplate>
                                <img
                                  class="h-full object-cover"
                                  src="/assets/imgs/product.png"
                                  alt="Product default"
                                />
                              </ng-template>
                            </div>
                            <div class="p-4 bg-white flex flex-col gap-[10px]">
                              <div class="font-medium text-sm line-clamp-1">
                                {{
                                  block.products?.[i]?.name || "Product name"
                                }}
                              </div>
                              <div class="flex items-center justify-between">
                                <span
                                  class="text-xl text-orange-dark-500 font-semibold"
                                  >$0</span
                                >
                                <div
                                  class="w-10 h-10 flex items-center justify-center rounded-full bg-orange-dark-500"
                                >
                                  <i
                                    class="{{
                                      SoctripIcons.SHOPPING_CART_03
                                    }} text-xl text-white"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- VOUCHER -->
                      <div *ngSwitchCase="BLOCK_TYPE.VOUCHER">
                        <div
                          class="grid gap-4 bg-gray-100 rounded-lg"
                          [ngStyle]="{
                            'grid-template-rows': getGridTemplate(block.line),
                            'grid-template-columns': getGridTemplate(
                              block.itemPerLine
                            )
                          }"
                        >
                          <div
                            *ngFor="
                              let item of generateArray(
                                block.line * block.itemPerLine
                              );
                              let i = index
                            "
                            class="rounded-lg border overflow-hidden h-28"
                          >
                            <!-- Voucher -->
                            <div
                              *ngIf="
                                block.vouchers[i];
                                else voucherDefaultTemplate
                              "
                              class="bg-white shadow-md h-full w-full rounded-lg flex"
                            >
                              <div
                                class="flex items-center justify-center h-full w-[120px] border-r border-dashed relative"
                              >
                                <div
                                  class="w-4 h-4 absolute rounded-full bg-gray-100 -right-2 top-0 -translate-y-1/2"
                                ></div>
                                <div
                                  class="w-4 h-4 absolute rounded-full bg-gray-100 right-[-8px] bottom-0 translate-y-1/2"
                                ></div>
                                <!-- Avatar -->
                                <div
                                  class="flex items-center justify-center h-16 w-16 rounded-md p-2 bg-opacity-50"
                                >
                                  <app-custom-image
                                    class="w-full h-full"
                                    [imgId]="block.vouchers[i].avatar_id"
                                    [alt]="block.vouchers[i].name"
                                  />
                                </div>
                              </div>
                              <div class="flex gap-1 flex-1 px-2 py-4">
                                <div
                                  class="flex-1 h-full flex flex-col gap-1 justify-center"
                                >
                                  <span class="font-semibold text-lg"
                                    >Discount 10%</span
                                  >
                                  <span class="text-sm">Min spend $9.00</span>
                                  <span class="text-sm text-gray-500"
                                    >Valid through 23:59, 09/09/2024</span
                                  >
                                </div>

                                <div
                                  class="text-sm font-medium h-fit py-2 px-4 rounded-lg bg-orange-dark-500 text-white"
                                >
                                  View
                                </div>
                              </div>
                            </div>

                            <ng-template #voucherDefaultTemplate>
                              <div class="bg-white flex">
                                <div
                                  class="h-28 w-28 flex items-center justify-center bg-gray-50"
                                >
                                  <img
                                    src="/assets/icons/voucher.svg"
                                    alt="Voucher Icon"
                                  />
                                </div>
                                <div class="flex-1 p-4 flex justify-between">
                                  <span class="text-lg font-semibold"
                                    >Voucher</span
                                  >
                                  <div
                                    class="text-sm font-medium h-fit py-2 px-4 rounded-lg bg-orange-dark-500 text-white"
                                  >
                                    View
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <!-- DROP LAYOUT -->
            <ng-template #dropLayerTemplate>
              <div
                dndDropzone
                dndDragoverClass="!bg-gray-100"
                (dndDrop)="onDropLayout($event, layerIndex)"
              >
                <div
                  class="flex flex-col gap-1 border border-transparent items-center justify-center py-6"
                >
                  <i class="{{ SoctripIcons.DOWNLOAD_02 }}  text-xl"></i>
                  <div class="text-sm">Drop layout here</div>
                </div>
              </div>
            </ng-template>

            <!-- ABSOLUTE ITEM OF LAYER -->
            <div
              [ngClass]="
                elementSelected.scope === 'LAYER' &&
                elementSelected.layerIndex === layerIndex
                  ? 'block'
                  : 'hidden'
              "
            >
              <!-- LAYER NAME -->
              <span
                class="absolute -translate-y-full -top-2 left-2 px-2 py-[2px] text-white text-xs rounded-full bg-gray-700"
              >
                {{ layer.name || "Layer" }}
              </span>

              <!-- ADD LAYER -->
              <div
                (click)="onAddLayer($event, layerIndex)"
                class="absolute z-10 left-2 -bottom-2 translate-y-full w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
              >
                <i class="{{ SoctripIcons.PLUS }} text-xl text-white"></i>
              </div>

              <!-- LAYER ACTIONS  -->
              <div
                class="absolute z-10 translate-x-full -right-1 top-0 flex flex-col gap-1"
              >
                <div
                  cdkDragHandle
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                >
                  <i class="{{ SoctripIcons.MENU_05 }} text-xl text-white"></i>
                </div>

                <div
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                  (click)="onCloneLayer(layerIndex)"
                >
                  <i class="{{ SoctripIcons.COPY_07 }} text-xl text-white"></i>
                </div>

                <div
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                  (click)="onDeleteLayer(layerIndex)"
                >
                  <i class="{{ SoctripIcons.TRASH_03 }} text-xl text-white"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT -->
    <app-right-bar-builder
      [elementSelected]="elementSelected"
      [(layers)]="layers"
    ></app-right-bar-builder>
  </div>
</div>

<ng-template #previewTemplate>
  <app-landing-page-preview
    [data]="layers"
    [previewDevice]="previewDevice"
  ></app-landing-page-preview>
</ng-template>
