<div class="px-5 border-t">
  <div class="py-2 flex items-center justify-between">
    <span class="text-sm font-semibold">Link</span>
    <div
      (click)="onToggleExpand()"
      class="w-9 h-9 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-lg"
    >
      <i
        [ngClass]="isExpand ? SoctripIcons.MINUS : SoctripIcons.PLUS"
        class="text-xl"
      ></i>
    </div>
  </div>

  <div *ngIf="isExpand && blockData.medias[0]" class="pb-5 flex flex-col gap-4">
    <input
      pInputText
      class="h-9 rounded-lg w-full"
      placeholder="https://"
      [(ngModel)]="blockData.medias[0].link1"
    />

    <div class="flex items-center gap-2">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="blockData.medias[0].isOpenNewTab"
      ></p-checkbox>
      <span class="font-medium text-sm">Open in new tab</span>
    </div>
  </div>
</div>
