import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
} from 'src/app/core/enum/marketing-builder';
import { Layer } from 'src/app/core/models/interfaces/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-landing-page-preview',
  templateUrl: './landing-page-preview.component.html',
  styleUrls: ['./landing-page-preview.component.scss'],
})
export class LandingPagePreviewComponent {
  @Input() data: Layer[] = [];
  @Input() previewDevice: string = PREVIEW_DEVICE.DESKTOP;

  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly SoctripIcons = SoctripIcons;

  constructor(public fileService: FileService) {}

  get isDesktop() {
    return this.previewDevice === PREVIEW_DEVICE.DESKTOP;
  }

  getGridTemplate(row: number) {
    return `repeat(${row}, minmax(0, 1fr))`;
  }

  generateArray(length: number) {
    return Array(length).fill(1);
  }
}
