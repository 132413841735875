import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: `https://tmtrav-voucher-hub-${PREFIX.DEV}.tma-swerp.com/`,
  // FE_URL: `https://marketplace-admin.tma-swerp.com/`,
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/`,
  // FE_URL_SALE: `https://marketplace.tma-swerp.com/`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  FE_SSO_URL: `https://${PREFIX.DEV}-sso.tma-swerp.com/`,
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
};
