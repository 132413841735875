export enum BLOCK_TYPE {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  SLIDE = 'SLIDE',
  VIDEO = 'VIDEO',
  TAB = 'TAB',
  PRODUCT_LIST = 'PRODUCT_LIST',
  VOUCHER = 'VOUCHER'
}

export enum PREVIEW_DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum TAB_MEDIA {
  MEDIA1 = 'MEDIA1',
  MEDIA2 = 'MEDIA2',
}

export enum PRODUCT_LIST_FILTER {
  PROMOTION = 'PROMOTION',
  KEYWORD = 'KEYWORD',
  CATEGORY = 'CATEGORY',
  PRODUCT_RATING = 'PRODUCT_RATING',
  CITY = 'CITY',
  SHOP = 'SHOP',
  PRICE_RANGE = 'PRICE_RANGE',
  PRODUCT = 'PRODUCT',
}
